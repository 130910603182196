import * as React from 'react';
import styled from 'styled-components';

import Row from '../components/Basic/Row';
import Col from '../components/Basic/Col';
import Container from '../components/Basic/Container';
import Footer from '../components/Layout/Footer';
import Seo from '../components/seo';

const List = styled.ol`
  padding-left: 20px;
`;

const ListItem = styled.li`
  color: ${({ theme }) => theme.neutralColor.textPrimary};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 0.925rem;
  letter-spacing: 0.05rem;
  line-height: 160%;
  margin: 0;
  white-space: normal;
  padding: 10px 0;
`;

const Section = styled.div`
  margin-bottom: 40px;
`;

const RegulationsPage = () => (
  <>
    <Seo title="JukonMedia.pl - Regulamin" />
    <Container>
      <Row style={{ padding: '30px 0' }}>
        <Col md={12}>
          <Section>
            <h2>Regulamin portalu internetowego www.jukonmedia.pl należącego do JukonMedia Mariusz Mischke</h2>
          </Section>
          <Section>
            <h2>§1. WSTĘP</h2>
            <List>
              <ListItem>
                Wydawcą i właścicielem portalu internetowego, którego dotyczy niniejszy Regulamin jest JukonMedia Mariusz Mischke, Bydgoszcz, ul. Bruska 11B, 85-422 Bydgoszcz, NIP 953-11-34-672, REGON:091326133,  email: mariusz@jukonmedia.pl.
              </ListItem>
              <ListItem>
                Poniższy Regulamin określa zasady funkcjonowania portalu www.jukonmedia.pl, prawa oraz obowiązki jego Użytkowników, warunki zawierania i rozwiązywania umów świadczenia usług drogą elektroniczną, tryb postępowania reklamacyjnego Użytkowników korzystających z portalu www.jukonmedia.pl.
              </ListItem>
              <ListItem>
                JukonMedia Mariusz Mischke udostępnia portal internetowy bezpłatnie Użytkownikom Internetu. Rozpoczęcie korzystania z portalu jest równoznaczne z wyrażeniem zgody na warunki przedstawione w Regulaminie. Każdy Użytkownik jest zobowiązany do przestrzegania postanowień Regulaminu.
              </ListItem>
              <ListItem>
                Zamieszczone na stronach portalu www.jukonmedia.pl informacje, teksty, artykuły nie mogą naruszać regulacji zawartych w ustawie z 4 lutego 1994r. o prawie autorskim i prawach pokrewnych (Dz. U. Nr 24, poz. 83 z p.z.), ustawy z dnia 27 lipca 2001 roku o ochronie baz danych oraz ustawy z dnia 16 kwietnia 1993 roku o zwalczaniu nieuczciwej konkurencji.
              </ListItem>
              <ListItem>
                JukonMedia Mariusz Mischkenie ponosi odpowiedzialności za treści komentarzy zamieszczanych pod tekstami oraz za treść wpisów Użytkowników na forum dyskusyjnym.
              </ListItem>
              <ListItem>
                JukonMedia Mariusz Mischke zastrzega sobie prawo moderowania oraz redagowania treści komentarzy i wpisów Użytkowników na forum dyskusyjnym.
              </ListItem>
              <ListItem>
                JukonMedia Mariusz Mischkezastrzega sobie prawo do usuwania treści niezgodnych z prawem, wulgarnych naruszających zasady współżycia społecznego i/lub niezgodnych
                z regulaminem danej usługi.
              </ListItem>
              <ListItem>
                JukonMedia Mariusz Mischke zastrzega sobie prawo do publikacji treści reklamowych w serwisie, newsletterach na forum i w mailingach rozsyłanych do Użytkowników, którzy korzystają z danej usługi.
              </ListItem>
              <ListItem>
                Korzystanie z portalu jest równoznaczne z akceptacją obowiązującej „Polityki prywatności i cookies”, której aktualna wersja jest opublikowana na stronie: Polityka prywatności i cookies odnośnik.
              </ListItem>
            </List>
          </Section>
          <Section>
            <h2>§2. DEFINICJE</h2>
            <p style={{ marginBottom: '10px' }}>
              Użyte w Regulaminie określenia mają następujące znaczenie:
            </p>
            <p style={{ marginBottom: '10px' }}>
              JukonMedia Mariusz Mischke– oznacza JukonMedia Mariusz Mischke z siedzibą w Bydgoszczy, ul. Bruska 11B, 85-422 Bydgoszcz, NIP 953-11-34-672, REGON:091326133.
            </p>
            <p style={{ marginBottom: '10px' }}>
              Portal – portal internetowy www.jukonmedia.pl , prowadzony przez JukonMedia Mariusz Mischke, dostępny pod aktualnym adresem www.jukonmedia.pl.
            </p>
            <p style={{ marginBottom: '10px' }}>
              Regulamin – oznacza niniejszy regulamin wraz z załącznikami.
            </p>
            <p style={{ marginBottom: '10px' }}>
              Użytkownik – osoba fizyczna, osoba prawna lub jednostka organizacyjna nieposiadająca osobowości prawnej ale posiadająca zdolność prawną, korzystająca portalu internetowego www.jukonmedia.pl.
            </p>
            <p style={{ marginBottom: '10px' }}>
              Przeglądarka internetowa – program komputerowy służący do pobierania i wyświetlania stron internetowych udostępnianych przez serwery WWW, a także do odtwarzania plików multimedialnych, często przy użyciu dodatkowych rozszerzeń zwanych wtyczkami.
            </p>
            <p>
              Administrator danych osobowych – oznacza organ, jednostkę organizacyjną, podmiot lub osobę decydującą (samodzielnie) o celach przetwarzania danych osobowych Użytkowników.
            </p>
          </Section>
          <Section>
            <h2>§3. WYMAGANIA TECHNICZNE</h2>
            <p>
              W celu prawidłowego korzystania z wszystkich mechanizmów dostępnych w ramach portalu internetowego www.jukonmedia.pl niezbędne jest spełnienie następujących wymagań technicznych:
            </p>
            <List>
              <ListItem>
                Urządzenia (np. komputer, smartfon, tablet) z dostępnym połączeniem do sieci (Internet).
              </ListItem>
              <ListItem>
                Przeglądarka internetowa umożliwiająca wyświetlanie na ekranie komputera dokumentów hipertekstowych (HTML) powiązanych w sieci Internet przez sieciową usługę WWW, obsługującą język oprogramowania JavaScript oraz umożliwiającą zapis plików Cookie.
              </ListItem>
              <ListItem>
                W przypadku Użytkowników dokonujących rejestracji – posiadanie konta poczty elektronicznej (adresu e-mail).
              </ListItem>
              <ListItem>
                JukonMedia Mariusz Mischkenie ponosi odpowiedzialności za treści komentarzy zamieszczanych pod tekstami oraz za treść wpisów Użytkowników na forum dyskusyjnym.
              </ListItem>
              <ListItem>
                Adobe Flash Player lub inna wtyczka zainstalowana w przeglądarce internetowej zapewniająca możliwość odtwarzania animacji wykonanej w technologii Flash.
              </ListItem>
            </List>
          </Section>
          <Section>
            <h2>§4. PRZECHOWYWANIE I OCHRONA DANYCH</h2>
            <List>
              <ListItem>
                Administratorem danych osobowych przetwarzanych na stronie portalu www.jukonmedia.pl jest JukonMedia Mariusz Mischke.
              </ListItem>
              <ListItem>
                Dane osobowe Użytkowników portalu wykorzystane wyłącznie w sposób zgodny
                z zakresem udzielonego nam przez Użytkownika zezwolenia. Korzystanie z portalu oznacza również zgodę na zapisywanie informacji w plikach cookies. Szczegółowe informacje w zakresie danych osobowych i ochrony prywatności zostały zawarte w zakładce „Polityka prywatności” odnośnik.
              </ListItem>
              <ListItem>
                Stosowane przez nas procedury gwarantują maksymalne bezpieczeństwo przechowywanych informacji. Powierzone nam dane są przechowywane z zachowaniem środków spełniających wymogi obowiązującego prawa.
              </ListItem>
              <ListItem>
                JukonMedia Mariusz Mischke nie przekazuje, nie sprzedaje i nie użycza zgromadzonych danych osobowych Użytkowników innym osobom lub instytucjom.
              </ListItem>
            </List>
          </Section>
          <Section>
            <h2>§5. TREŚCI</h2>
            <List>
              <ListItem>
                Użytkownicy portalu www.jukonmedia.pl mogą samodzielnie zamieszczać dowolne treści (w szczególności filmy, pliki multimedialne, audiowizualne, graficzne, muzyczne, tekstowe, zdjęcia linki do innych stron internetowych, komentarze, opinie, obrazki, itp.). Treści zamieszczone przez Użytkownika muszą być zgodne z prawem i niniejszym Regulaminem, przy czym JukonMedia Mariusz Mischke., jako podmiot dostarczający jedynie platformy technicznej, umożliwiającej Użytkownikom umieszczanie w niej dowolnych treści skierowanych do szerokiego kręgu odbiorców sieci, nie odpowiada za zgodność tych treści z obowiązującymi przepisami prawa, ich prawdziwość, rzetelność, skuteczność tym samym nie ingeruje m.in. w przebieg dyskusji, nie odpowiada również za regulamin i politykę prywatności stosowaną przez właścicieli stron internetowych, których linki zostały umieszczone przez Użytkowników w portalu internetowym www.jukonmedia.pl, ani za treść informacji tam zawartych. Użytkownicy zamieszczający treści w portalu, w tym linki do stron internetowych osób trzecich rozpowszechniają je na własną odpowiedzialność i są wyłącznie odpowiedzialni za roszczenia osób trzecich z tytułu naruszenia ich praw.
              </ListItem>
              <ListItem>
                Zabronione jest publikowanie jakichkolwiek treści, które są sprzeczne z prawem polskim i międzynarodowym, wzywającym do nienawiści rasowej, etnicznej, wyznaniowej lub propagujących przemoc, obrażających uczucia innych osób, w tym również uczucia religijne, naruszające prawa do prywatności. Niedopuszczalne jest również zamieszczanie treści zawierających nagość, pornografię erotykę oraz treści obsceniczne, treści nieetyczne lub powszechnie uznawane za naganne moralnie i społecznie oraz naruszające zasady etykiety lub niezgodnych z tematyką portalu www.jukonmedia.pl.
              </ListItem>
              <ListItem>
                Zabronione jest publikowanie na stronach portalu www.jukonmedia.pl wszelkich treści zawierających materiały chronione prawem autorskim bez zgody autora.
              </ListItem>
              <ListItem>
                Na stronach portalu www.jukonmedia.pl prezentowane są materiały zawierające treści informacyjne oraz reklamowe.
              </ListItem>
              <ListItem>
                JukonMedia Mariusz Mischkema prawo zamieszczania na stronach portalu www.jukonmedia.pl treści reklamowych dotyczących usług oraz towarów własnych oraz podmiotów współpracujących.
              </ListItem>
            </List>
          </Section>
          <Section>
            <h2>§6. UPRAWNIENIA UŻYTKOWNIKA</h2>
            <p>
              Użytkownik portalu www.jukonmedia.pl ma prawo do:
            </p>
            <List>
              <ListItem>
                Swobodnego przeglądania portalu, w tym przeglądania treści zamieszczonych przez innych Użytkowników, przy czym w niektórych wypadkach, przeglądanie możliwe jest po uprzedniej rejestracji.
              </ListItem>
              <ListItem>
                Dokonywania transakcji handlowych w sieci Internet, w szczególności zawierać umowy sprzedaży oraz umowy o świadczenie usług.
              </ListItem>
              <ListItem>
                Korzystać z Serwisów społecznościowych umożliwiających prezentację osoby Użytkownika, nawiązywanie i utrzymywanie kontaktów pomiędzy poszczególnymi Użytkownikami w celu wymiany poglądów i informacji, w szczególności w ramach czatów, forów dyskusyjnych itp.
              </ListItem>
              <ListItem>
                Zamieszczania na portalu własnych treści za zasadach określonych w niniejszym Regulaminie.
              </ListItem>
              <ListItem>
                Dostępu do treści swoich danych osobowych i ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych, prawo do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania.
              </ListItem>
              <ListItem>
                Rezygnacji z korzystania z portalu www.jukonmedia.pl oraz wypowiedzenia Umowy o świadczenie Usługi.
              </ListItem>
            </List>
          </Section>
          <Section>
            <h2>§7. REKLAMACJE</h2>
            <List>
              <ListItem>
                Każdemu Użytkownikowi portalu internetowego www.jukonmedia.pl przysługuje prawo do złożenia reklamacji w sprawach związanych z funkcjonowaniem serwisu.
              </ListItem>
              <ListItem>
                Reklamacje należy składać drogą elektroniczną na adres:
                e-mail: mariusz@jukonmedia.pl lub listownie na adres: JukonMedia Mariusz Mischke, Bydgoszcz, ul. Bruska 11B, 85-422 Bydgoszcz z dopiskiem „jukonmedia.pl”.
              </ListItem>
              <ListItem>
                Wydawca i właściciel portalu JukonMedia Mariusz Mischke rozpatrzy reklamację w terminie 14 dni od dnia jej otrzymania.
              </ListItem>
              <ListItem>
                Odpowiedź na reklamację zostanie przesłana do Użytkownika na adres podany przez Użytkownika w reklamacji.
              </ListItem>
              <ListItem>
                Usługodawca zastrzega sobie prawo do wydłużenia terminu podanego w pkt 3 powyżej – o nie więcej niż 10 dni – w przypadku, gdy rozpoznanie reklamacji wymaga nietypowych, szczególnych działań i ustaleń lub napotka przeszkody niezależne od Właściciela serwisu (awarie sprzętu, sieci internetowej itp.). JukonMedia Mariusz Mischke ponadto zastrzega, że rozpatrzenie reklamacji może wymagać uzyskania od Użytkownika dodatkowych wyjaśnień. Czas udzielania wyjaśnień przez Użytkownika każdorazowo przedłuża okres rozpoznania reklamacji o czas wyjaśnień.
              </ListItem>
            </List>
          </Section>
          <Section>
            <h2>§8. KONTAKT</h2>
            <p>Wszelkie pytania lub uwagi dotyczące  regulaminu, funkcjonowania lub warunków korzystania z portalu www.jukonmedia.pl prosimy przesyłać na adres e-mail: mariusz@jukonmedia.pl</p>
          </Section>
          <Section>
            <h2>§9. POSTANOWIENIA KOŃCOWE</h2>
            <List>
              <ListItem>
                Niniejszy Regulamin wchodzi w życie z dniem 15.11.2021 roku.
              </ListItem>
              <ListItem>
                Korzystanie z portalu jest równoznaczne z akceptacją wszystkich postanowień Regulaminu.
              </ListItem>
              <ListItem>
                JukonMedia Mariusz Mischke zastrzega sobie prawo do zmiany treści Regulaminu w każdym czasie. Zmiany będą publikowane na bieżąco w postaci ujednoliconego tekstu Regulaminu na łamach portalu www.jukonmedia.pl wraz z informacją o ich dokonaniu.
              </ListItem>
              <ListItem>
                Korzystanie przez Użytkownika z portalu www.jukonmedia.pl po wprowadzeniu zmian Regulaminu oznacza ich akceptację. W przypadku nie zaakceptowania zmian w Regulaminie Użytkownik powinien powstrzymać się od korzystania z portalu www.jukonmedia.pl.
              </ListItem>
              <ListItem>
                W kwestiach nieuregulowanych niniejszym Regulaminem stosuje się odpowiednio przepisy Kodeksu Cywilnego oraz innych obowiązujących przepisów prawa.
              </ListItem>
              <ListItem>
                Spory wynikające ze stosowania Regulaminu, po wyczerpaniu postępowania reklamacyjnego, rozstrzygane będą przed Sądem Powszechnym właściwym dla siedziby JukonMedia Mariusz Mischke.
              </ListItem>
            </List>
          </Section>
        </Col>
      </Row>
    </Container>
    <Container>
      <Footer />
    </Container>
  </>
);

export default RegulationsPage;
